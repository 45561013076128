<template>
  <div>
    <el-form
      label-position="right"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="60px"
      style="text-align:left"
    >
      <el-col :span="12">
        <el-form-item label="购买的短信数" label-width="110px" prop="sms_buy_num">
          <el-input
            v-model.number="ruleForm.sms_buy_num"
            style="width: 100%"
            placeholder="请输入购买的短信数"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="剩余短信数" label-width="110px" prop="sms_surplus_num">
          <el-input v-model.number="ruleForm.sms_surplus_num" placeholder="请输入剩余短信数"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="购买的合同数" label-width="110px" prop="contract_buy_num">
          <el-input
            v-model.number="ruleForm.contract_buy_num"
            style="width: 100%"
            placeholder="请输入购买的合同数"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="剩余合同数" label-width="110px" prop="contract_surplus_num">
          <el-input v-model.number="ruleForm.contract_surplus_num" placeholder="请输入剩余合同数"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="购买的认证数" label-width="110px" prop="auth_buy_num">
          <el-input
            v-model.number="ruleForm.auth_buy_num"
            style="width: 100%"
            placeholder="请输入购买的认证数"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="剩余认证数" label-width="110px" prop="auth_surplus_num">
          <el-input v-model.number="ruleForm.auth_surplus_num" placeholder="请输入剩余认证数"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="购买的发放笔数" label-width="110px" prop="pay_buy_num">
          <el-input
            v-model.number="ruleForm.pay_buy_num"
            style="width: 100%"
            placeholder="请输入购买的发放笔数"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="剩余发放笔数" label-width="110px" prop="pay_surplus_num">
          <el-input v-model.number="ruleForm.pay_surplus_num" placeholder="请输入剩余发放笔数"></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <el-button style="float: right;" type="primary" @click="onSubmit('ruleForm')">保存</el-button>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const customerEdit = api()('enterprise.config.edit.json');

export default {
  props: {
    code: String,
    userInfo: Object
  },
  data () {
    return {
      ruleForm: {
        contract_buy_num: '',
        contract_surplus_num: '',
        sms_buy_num: '',
        sms_surplus_num: '',
        auth_buy_num: '',
        auth_surplus_num: '',
        pay_buy_num: '',
        pay_surplus_num: '',
      },
      rules: {
        sms_buy_num: [{ required: true, message: '请输入购买短信数', trigger: 'blur' }],
        sms_surplus_num: [{ required: true, message: '请输入剩余短信数', trigger: 'blur' }]
      },
    }
  },
  mounted () {
    Object.keys(this.ruleForm).forEach(it => {
      this.ruleForm[it] = this.userInfo[it]

    })
    console.log(this.userInfo);
  },
  methods: {
    onSubmit (formName) {
      console.log(typeof (this.ruleForm.contract_buy_num), formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          customerEdit({
            ...this.ruleForm,
            code: this.code
          }
          ).then(() => {

            this.$message({
              message: '保存成功',
              type: 'success'
            });
            // window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    }
  },
}
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>